import { Injectable } from '@angular/core';
import * as highCharts from 'highcharts/highstock';
import * as highChartsHeatmap from 'highcharts/modules/heatmap';
import * as highChartsBoost from 'highcharts/modules/boost';
import * as highChartsExporting from 'highcharts/modules/exporting';
import * as highChartsHistogram from 'highcharts/modules/histogram-bellcurve';
import * as moment from 'moment-timezone';
import { highChartsTimeLineSeries } from './chart-configs/highcharts-time-line-series.js';
import { highChartsPieChart } from './chart-configs/highcharts-pie-chart.js';
import { highChartsHeatMap } from './chart-configs/highcharts-heatmap.js';
import { highChartsHistogramChart } from './chart-configs/highcharts-histogram-chart.js';
import { highChartsDualAxes } from '@shared/components/la-chart/chart-configs/highcharts-dual-axes.js';
import { highChartsMultiAxes } from '@shared/components/la-chart/chart-configs/highcharts-multi-axes.js';
import { highChartsScatter } from '@shared/components/la-chart/chart-configs/highcharts-scatter.js';
import { FieldService } from '@core/services/field/field.service';
import { deepCopy } from '@shared/utils/storage/encrypted_storage';

(window as any).moment = moment;
// @ts-ignore
highChartsHeatmap(highCharts);
// @ts-ignore
highChartsBoost(highCharts);
// @ts-ignore
highChartsExporting(highCharts);
// @ts-ignore
highChartsHistogram(highCharts);
// @ts-ignore
highChartsPieChart(highCharts);
// @ts-ignore
highChartsDualAxes(highCharts);
// @ts-ignore
highChartsMultiAxes(highCharts);
// @ts-ignore
highChartsScatter(highCharts);

@Injectable()
export class LaChartService {
  chart;

  constructor(private fieldsService: FieldService) {}

  makeChart(data: any, nativeElement: any) {
    let highChartTemplate = null as any;
    if (data.item.data_view_type_id === 1) {
      // Time Series Chart
      let item = data.item;



      if (item.time_domain_interval_id === 5) {
        let curdate = new Date();
        let offset = curdate.getTimezoneOffset() / 60;

        for (let i = 0; i < item.collection[0]['data'].length; i++) {
          let itm = item.collection[0]['data'][i];

          let processedTime = new Date(itm);
          processedTime.setDate(processedTime.getDate() + 1);
          processedTime = this.subtractHours(processedTime, 9 + offset);

          let year: any = processedTime.getFullYear();
          let month: any = processedTime.getMonth() + 1;
          let day: any = processedTime.getDate();
          let hours: any = processedTime.getHours();
          let seconds: any = processedTime.getSeconds();
          let minutes: any = processedTime.getMinutes();

          if (month < 10) {
            month = `0${month}`;
          }

          if (day < 10) {
            day = `0${day}`;
          }

          if (seconds < 10) {
            seconds = `0${seconds}`;
          }

          if (minutes < 10) {
            minutes = `0${minutes}`;
          }

          if (hours < 10) {
            hours = `0${hours}`;
          }

          item.collection[0]['data'][
            i
          ] = `${year}-${month}-${day}T${hours}:${minutes}Z`;
        }
      }

      

      else if ([1, 2, 3, 4].includes(item.time_domain_interval_id))  {
        if (![1, 3, 5, 7,].includes(item.time_domain_id)) {
          let curdate = new Date();
          let offset = curdate.getTimezoneOffset() / 60;

          for (let i = 0; i < item.collection[0]['data'].length; i++) {
            let itm = item.collection[0]['data'][i];

            let processedTime = new Date(itm);
            processedTime.setDate(processedTime.getDate() + 1);
            processedTime = this.subtractHours(processedTime, 15 + offset);

            let year: any = processedTime.getFullYear();
            let month: any = processedTime.getMonth() + 1;
            let day: any = processedTime.getDate();
            let hours: any = processedTime.getHours();
            let seconds: any = processedTime.getSeconds();
            let minutes: any = processedTime.getMinutes();

            if (month < 10) {
              month = `0${month}`;
            }

            if (day < 10) {
              day = `0${day}`;
            }

            if (seconds < 10) {
              seconds = `0${seconds}`;
            }

            if (minutes < 10) {
              minutes = `0${minutes}`;
            }

            if (hours < 10) {
              hours = `0${hours}`;
            }

            item.collection[0]['data'][
              i
            ] = `${year}-${month}-${day}T${hours}:${minutes}Z`;
          }
        }
      }

      
      highChartTemplate = highChartsTimeLineSeries(item);
      this.chart = highCharts.stockChart(nativeElement, highChartTemplate);
    } else if (data.item.data_view_type_id === 2) {
      // Pie Chart.... mmmmmh pie....
      highChartTemplate = highChartsPieChart(data.item);
      this.chart = highCharts.chart(nativeElement, highChartTemplate);
    } else if (data.item.data_view_type_id === 4) {
      // Pie Chart.... ahhh heat!!!....
      highChartTemplate = highChartsHeatMap(data.item);
      this.chart = highCharts.chart(nativeElement, highChartTemplate);
    } else if (data.item.data_view_type_id === 5) {
      let records = [];
      let labels = [];
      let name = data.item.name;

      if (data.item.collection && data.item.collection.length > 0) {
        for (let i = 0; i < data.item.collection[0].count; i++) {
          records.push(data.item.collection[0].data[i].value);
          labels.push(
            `${data.item.collection[0].name} - ${data.item.collection[0].data[i].name}`
          );
        }
      }

      highChartTemplate = highChartsHistogramChart(name, records, labels);
      if (nativeElement !== undefined) {
        this.chart = highCharts.chart(nativeElement, highChartTemplate);
      }
    } else if (data.item.data_view_type_id === 8) {
      let collection = data.item.collection;
      let factor = data.item.data_points.length;
      let yAxis: any = this.prepareYAxisScatter(data);
      let xAxis: any = this.prepareXAxisScatter(data);
      let records = [];
      let labels = [];
      if (typeof collection !== 'undefined' && collection !== null) {
        if (collection.length > 0) {
          collection.forEach((item: any) => {
            labels.push(item.name);
          });

          let update_collection: any[] = [];

          let index = 0;
          collection.forEach((item: any) => {
            let itm = {
              name: item.name,
              color: item.graph.lineColor,
              data: this.extractTupledDates(item.data)
            };
            update_collection.push(itm);
            index++;
          });
          collection = update_collection;
        }
      }

      highChartTemplate = highChartsScatter(
        name,
        collection,
        labels,
        xAxis,
        yAxis
      );
      this.chart = highCharts.chart(nativeElement, highChartTemplate);
    } else if (data.item.data_view_type_id === 6) {
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];
      let result = this.convertToDual(data);

      let records = [];
      let yAxis: any[] = this.prepareYAxisDual(data);

      // Dual Axes chart
      let name = data.item.name;
      let date = new Date();
      let labels: any[] = [];

      let dt = new Date();
      let month = dt.getMonth();
      let year = dt.getFullYear();
      if (data.item.time_domain_id === 1) {
        let today = new Date();
        let todMonth = months[today.getMonth()];
        let tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        let tomMonth = months[tomorrow.getMonth()];
        labels.push(`${today.getDate()} ${todMonth}`);
        labels.push(`${tomorrow.getDate()} ${tomMonth}`);
      } else if (data.item.time_domain_id === 2) {
        let today = new Date();
        let todMonth = months[today.getMonth()];
        let yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        let yestMonth = months[yesterday.getMonth()];
        labels.push(`${yesterday.getDate()} ${yestMonth}`);
        labels.push(`${today.getDate()} ${todMonth}`);
      } else if (data.item.time_domain_id === 3) {
        let curr = new Date();
        let week = [];

        for (let i = 1; i <= 7; i++) {
          let first = curr.getDate() - curr.getDay() + i;
          let sday = new Date(curr.setDate(first)).toISOString().slice(0, 10);
          let day = parseInt(sday.slice(8.1));
          week.push(`${day} ${months[curr.getMonth()]}`);
          if (i === 7) {
            let mon = 0;
            let yr = sday.slice(0, 4);
            let mn = sday.slice(5, 7);
            if (mn.slice(0, 1) === '0') {
              mon = parseInt(mn.slice(1, 2));
            } else {
              mon = parseInt(mn);
            }

            let m = months[mon - 1];
            let d = new Date(`${m} ${day}, ${yr}`);
            let tomorrow = new Date(d.getTime() + 24 * 60 * 60 * 1000);
            week.push(`${tomorrow.getDate()} ${m}`);
          }
        }
        labels = week;
      }
      if (data.item.time_domain_id === 6) {
        month = month - 1;
        if (month === -1) {
          month = 11;
        }
        if (month === 11) {
          year = year - 1;
        }
      }

      let daysInMonth = this.daysInMonth(month + 1, year);

      if (data.item.time_domain_id !== 1 && data.item.time_domain_id !== 2) {
        if (
          data.item.time_domain_interval_id === 5 &&
          data.item.time_domain_id !== 3
        ) {
          for (let i = 1; i <= daysInMonth; i++) {
            labels.push(`${i} ${months[month]}`);
          }
        }
      }

      data.item.collection = result;
      let collection = data.item.collection;

      records = [];
      if (typeof collection !== 'undefined' && collection !== null) {
        if (collection.length > 0) {
          if (collection[0] !== 'undefined') {
            for (let i = 0; i < collection[0].data.length; i++) {
              //              labels.push(collection[0].data[i].name);
            }
          }
          for (let i = 0; i < collection.length; i++) {
            let d = [];
            for (let j = 0; j < collection[i].data.length; j++) {
              d.push(collection[i].data[j].value);
            }
            if (data.item.auto_color) {
              records.push({
                name: collection[i].name,
                type: collection[i].type,
                yAxis: i,
                data: d
              });
            } else {
              records.push({
                color: data.item.data_points[i].color,
                name: collection[i].name,
                type: collection[i].type,
                yAxis: i,
                data: d
              });
            }
          }
        }
      }
      let xAxis = this.prepareXAxisDual(labels);
      highChartTemplate = highChartsDualAxes(
        name,
        records,
        labels,
        xAxis,
        yAxis
      );
      delete highChartTemplate['xAxis'][0]['type'];
      this.chart = highCharts.chart(nativeElement, highChartTemplate);
    } else if (data.item.data_view_type_id === 7) {
    }
    return this.chart;
  }

  extractTupledDates(data) {
    let extracted: any[] = [];

    let month = new Array();
    month[0] = '01';
    month[1] = '02';
    month[2] = '03';
    month[3] = '04';
    month[4] = '05';
    month[5] = '06';
    month[6] = '07';
    month[7] = '08';
    month[8] = '09';
    month[9] = '10';
    month[10] = '11';
    month[11] = '12';

    for (let i = 0; i < data.length; i++) {
      let d = new Date(data[i][1]);

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      let minuts = minutes < 10 ? '0' + minutes : minutes;
      let strTime = hours + ':' + minuts;
      let day: any = d.getDate();

      if (day < 10) {
        day = `0${day}`;
      }
      let v = `${d.getFullYear()}-${month[d.getMonth()]}-${day} ${strTime}`;
      /*
            d.getDate() +
            ' ' +
            month[d.getMonth()] +
            ' ' +
            d.getFullYear() +
            ' ' +
            strTime;
*/
      //      let item = [Date.UTC(d.getFullYear(), d.getMonth(), d.getDay()), data[i][0]];
      let item = [
        data[i][0],
        Date.UTC(d.getFullYear(), d.getMonth(), d.getDay())
      ];
      extracted.push(item);
    }
    return extracted;
  }
  extractDates(data) {
    const dates = [];
    let extractedDates = null;
    for (let i = 0; i < data.collection.length; i++) {
      if (data.collection[i].field === 'date') {
        extractedDates = JSON.parse(
          JSON.stringify(data.collection[i].data.reverse())
        );
        data.collection.splice(i, 1);
        break;
      }
    }
    if (extractedDates) {
      for (const date of extractedDates) {
        // turn utc datetime to utc epoch...
        dates.push(new Date(date).getTime());
      }
    }
    return dates;
  }

  getSeriesData(data) {
    /*if(data.id === 17){
      //  console.log(JSON.stringify(data));
    }*/

    const seriesData = [];
    let yAxisMin = null;
    if (data.collection.length) {
      const dates = this.extractDates(data);
      data.collection.forEach(dc => {
        const dpData = [];
        dc.data.reverse();
        for (let j = 0; j < dc.data.length; j++) {
          const xVal = dc.data[j];
          if (!yAxisMin) {
            yAxisMin = xVal;
          }
          if (xVal && xVal < yAxisMin) {
            yAxisMin = xVal;
          }
          dpData.push([dates[j], xVal]);
        }
        let seriesType = 'area';
        if (typeof dc !== 'undefined' && dc !== null) {
          if (typeof dc.graph !== 'undefined' && dc.graph !== null) {
            if (
              typeof dc.graph.type !== 'undefined' &&
              dc.graph.type !== null
            ) {
              seriesType = dc.graph.type;
            }
          }
        }
        seriesData.push({
          type: seriesType,
          name: dc.name,
          data: dpData,
          showInNavigator: true,
          turboThreshold: Number.MAX_VALUE,
          color: dc.graph.lineColor,
          connectNulls: dc.graph.connectNulls,
          fillColor: highCharts
            .color(dc.graph.lineColor)
            .setOpacity(dc.graph.fillAlphas || 0.2)
            .get('rgba'),
          lineWidth: dc.graph.lineThickness || 2
        });
      });
    }
    return { yAxisMin, seriesData };
  }

  getPieSeriesData(data) {
    const seriesData = [];
    if (data.collection.length) {
      data.collection.forEach(item => {
        seriesData.push({
          name: item.name,
          y: item.data,
          colorByPoint: true
        });
      });
    }
    return [{ colorByPoint: true, data: seriesData }];
  }

  getMultiAxesData(data: any) {
    let yAxisMin = 0;
    let seriesData = [];
    let collection = [];

    seriesData = collection;
    return { yAxisMin, seriesData };
  }

  getDualAxesData(data: any) {
    let yAxisMin = 0;

    let collection = [];

    const seriesData = collection;
    return { yAxisMin, seriesData };
  }

  getScatterSeriesData(data: any) {
    /*if(data.id === 17){
      //  console.log(JSON.stringify(data));
    }*/
    let yAxisMin = 0;
    const seriesData = [];
    return { yAxisMin, seriesData };
  }

  getHistogramSeriesData(data: any) {
    /*if(data.id === 17){
      //  console.log(JSON.stringify(data));
    }*/
    let yAxisMin = 0;
    const seriesData = [];
    return { yAxisMin, seriesData };
  }

  getDualSeriesData(data: any) {
    const seriesData = [];
    let yAxisMin = null;
    if (data.collection.length) {
      const dates = this.extractDates(data);
      data.collection.forEach(dc => {
        const dpData = [];
        dc.data.reverse();
        for (let j = 0; j < dc.data.length; j++) {
          const xVal = dc.data[j];
          if (!yAxisMin) {
            yAxisMin = xVal;
          }
          if (xVal && xVal < yAxisMin) {
            yAxisMin = xVal;
          }
          dpData.push([dates[j], xVal]);
        }
        let seriesType = 'area';
        if (typeof dc !== 'undefined' && dc !== null) {
          if (typeof dc.graph !== 'undefined' && dc.graph !== null) {
            if (
              typeof dc.graph.type !== 'undefined' &&
              dc.graph.type !== null
            ) {
              seriesType = dc.graph.type;
            }
          }
        }
        seriesData.push({
          type: seriesType,
          name: dc.name,
          data: dpData,
          showInNavigator: true,
          turboThreshold: Number.MAX_VALUE,
          color: dc.graph.lineColor,
          connectNulls: dc.graph.connectNulls,
          fillColor: highCharts
            .color(dc.graph.lineColor)
            .setOpacity(dc.graph.fillAlphas || 0.2)
            .get('rgba'),
          lineWidth: dc.graph.lineThickness || 2
        });
      });
    }
    return { yAxisMin, seriesData };
  }

  getHeatMapSeriesData(data: any) {
    const tdi = this.fieldsService.fieldDataSource.value.time_domain_intervals.find(
      t => t.id === data.time_domain_interval_id
    );
    const dpData = [];
    const seriesData = [];
    const categories = [];
    if (data.collection.length) {
      const dates = this.extractDates(data);
      // todo NG10 check
      data.collection.forEach((col, i) => {
        categories.push(col.graph.title);
        col.data.reverse();
        col.data.forEach((value, j) => {
          dpData.push([dates[j], i, value]);
        });
      });
      seriesData.push({
        nullColor: '#EFEFEF',
        colsize: tdi.interval_length * 60000,
        data: dpData,
        turboThreshold: Number.MAX_VALUE
      });
    }
    return { seriesData, categories };
  }

  convertToDual(data: any): any {
    let data_points = data.item.data_points;

    let d = data.item.collection.filter(function(e) {
      return e.field == 'date';
    });

    if (typeof d !== 'undefined' && d !== null) {
      if (d.length > 0) {
        if (typeof d[0].data !== 'undefined') {
          let axisX = d[0].data;
          const dates = this.extractDates(data.item);
          let views = data.item.collection.filter(function(e) {
            return e.field != 'date';
          });
          let collection: any[] = [];
          let index = 0;

          views.forEach((view: any) => {
            let datas: any[] = [];
            for (let i = 0; i < axisX.length; i++) {
              let key = new Date(dates[i]);
              let split_key = key
                .toString()
                .split(' GMT')[0]
                .slice(0, key.toString().split(' GMT')[0].length - 3);

              let val = view.data[i];
              let one: any = { name: split_key, value: val };
              datas.push(one);
            }

            let item = {
              name: view.name,
              yAxis: index + 1,
              type: `${data_points[index].data_point_type}`,
              opposite: view.opposite,
              data: datas
            };
            collection.push(item);
            index++;
          });
          return collection;
        }
      }
    }
    return [];
  }

  prepareXAxisScatter(data: any): any {
    let axis = {
      type: 'datetime',
      title: {
        enabled: true,
        text: data.item.x_axis_label
      },
      dateFormat: 'YYYY-mm-dd',
      dateTimeLabelFormats: {
        // don't display the dummy year
        month: '%e %b %Y',
        year: '%Y'
      },
      labels: {
        formatter: function() {
          return (
            highCharts.dateFormat('%e. %m.', this.value) +
            ' - ' +
            highCharts.dateFormat('%l. %M.', this.value)
          );
        }
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true
      //  startOnTick: false,
      //  endOnTick: false,
      //  showLastLabel: false
    };
    return axis;
  }

  prepareYAxisScatter(data: any): any {
    let axis = {
      title: {
        text: data.item.y_axis_label
      },
      dateFormat: 'YYYY-mm-dd',
      dateTimeLabelFormats: {
        // don't display the dummy year
        month: '%e %b %Y',
        year: '%Y'
      },
      labels: {
        formatter: function() {
          return (
            highCharts.dateFormat('%e. %m.', this.value) +
            ' - ' +
            highCharts.dateFormat('%l. %M.', this.value)
          );
        }
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true
      //  startOnTick: false,
      //  endOnTick: false,
      //   showLastLabel: false
    };
    return axis;
  }

  prepareXAxisDual(labels: any) {
    let xAxis: any[] = [];
    let axis = {
      endOfTick: false,
      categories: labels,
      opposite: false,
      gridLineWidth: 1,
      tickInterval: 1,
      ordinal: true,
      crosshair: true,
      scrollbar: {
        enabled: true
      }
    };
    xAxis.push(axis);
    return xAxis;
  }

  prepareYAxisDual(data: any): any {
    let yAxis: any[] = [];
    let dps = data.item.data_points;
    let views = data.item.collection.filter(function(e) {
      return e.field != 'date';
    });

    let index = 0;

    dps.forEach((view: any) => {
      let item: any = {};
      item = {
        labels: {
          //        format: `{value}`,
          style: {
            color: data.item.auto_color
              ? highCharts.getOptions().colors[index]
              : data.item.data_points[index].color
          }
        },
        title: {
          text: view.name,
          style: {
            color: data.item.auto_color
              ? highCharts.getOptions().colors[index]
              : data.item.data_points[index].color
          }
        }
      };
      if (typeof views !== 'undefined' && views !== null) {
        if (typeof views[index] !== 'undefined' && views[index] !== null) {
          if (
            typeof views[index]['field'] !== 'undefined' &&
            views[index]['field'] !== null
          ) {
            let id = views[index]['field'].toString().split('_')[
              views[index]['field'].toString().split('_').length - 1
            ];
            if (view.id == id) {
              item['opposite'] = views[index]['opposite'];
            }
          }
        }
      }
      yAxis.push(item);
      index++;
    });

    return yAxis;
  }

  generateChunks(arr: any[], bulkSize: number) {
    const bulks = [];
    for (let i = 0; i < Math.ceil(arr.length / bulkSize); i++) {
      bulks.push(arr.slice(i * bulkSize, (i + 1) * bulkSize));
    }
    return bulks;
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  subtractHours(date, hours): any {
    date.setHours(date.getHours() - hours);
    return date;
  }

  isThisYear(dt:any): boolean {
      let dateArray = dt.split("T");
      let someDate = new Date(dateArray[0]);
      let thisYear = new Date();
      let this_year:boolean = false;
      if (thisYear.getFullYear() == someDate.getFullYear()) {
          this_year = true; 
      }
      return this_year;
  }

  isThisMonth(dt:any): boolean {
      let this_month:boolean = false;
      let dateArray = dt.split("T");
      let someDate = new Date(dateArray[0]);
      let thisMonth = new Date();
      
      if (thisMonth.getMonth() == someDate.getMonth()) {
          if (thisMonth.getFullYear() == someDate.getFullYear()) {
             this_month = true;
          }
      }
      return this_month;
  }

  isThisWeek (dt:any): boolean {

    let dateArray = dt.split("T");
    let date = new Date(dateArray[0]);
    let todayObj = new Date();
    let todayDate = todayObj.getDate();
    let todayDay = todayObj.getDay();
    let thisWeek:boolean = false;
    // get first date of week
    let firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    let lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    // if date is equal or within the first and last dates of the week
    if (date >= firstDayOfWeek && date <= lastDayOfWeek) {
         thisWeek = true;
    }
    return thisWeek;
  }

  isToday(dt:any): boolean {
      let dateArray = dt.split("T");
      let day = parseInt(dateArray[0].split('-')[2])

      let someDate = new Date(dateArray[0]);
     
      let today = new Date();
      let td:boolean = false;
      if (day == parseInt(`${today.getDate()}`)) {
          td = true;
      }
      return td;
  }

  sliceList(arr:any[], index:number):any[] {
      let updatedArr:any[] = [];

      for (let i = 0; i<arr.length; i++) {
          if (i!==index) {
            updatedArr.push(arr[i]);
          }
      }

      return updatedArr;
  }


  includesIndex(indexes:any[], index:number): boolean {
      let found:boolean = false;
      for (let i = 0; i < indexes.length; i++) {
          if (indexes[i] === index) {
              found = true;
          }
      }
      return found;
  }
}
