import { Action } from '@ngrx/store';

export enum EventsActionTypes {
  RedirectToDefault = '[Event] Redirect To Default',
  UserChangedFromSuper = '[Event] User Changed From Super',
  UpdateDashboardMenuItem = '[Event] Update Dashboard Menu Intem',
  DeleteDashboard = '[Event] Delete Dashboard',
  OpenDeleteDashboardModal = '[Event] Open Delete Dashboard Modal',
  StartGridSpinner = '[Event] Start Grid Spinner',
  StopGridSpinner = '[Event] Stop Grid Spinner',
  OpenDeleteChartItemModal = '[Event] Open Delete Chart Item Modal',
  DeleteChartItem = '[Event] Delete Chart item',
  UpdateGridChart = '[Event] Update Grid Chart',
  UpdateChartItems = '[Event] Update Chart Items',
  RefreshGridChart = '[Event] Refresh Grid Chart',
  RefreshGridChartHeader = '[Event] Refresh Grid ChartHeader',
  RefreshGridChartFinished = '[Event] Refresh Grid Chart Finished',
  PopulateFormula = '[Event] Populate Formula',
  RefreshFormula = '[Event] Refresh Formula',
  RereadColumnNames = '[Event] Reread Column Names',
  PostFormula = '[Event] Post Formula',
  ReadDataPointConfigs = '[Event] Read Data Point Configs',
  ReloadPage = '[Event] Reload Page',
  InitComputedActive = '[Event] Init Computed Active',
  HideHeaderLabels = '[Event] Hide Header Labels',
  PostAxisValues = '[Event] Post Axis Values',
  HideAlignOnThreeD = '[Event] Hide Align Left Right on 3d',
  AddLetterToAdvancedExpression = '[Event] Add Letter To Advanced Expression',
  DoNotUseAdvancedExpression = '[Event] Do Not Use Advanced Expression',
  UseAdvancedExpression = '[Event] Use Advanced Expression',
  NotifyDashboard = '[Event] Notify Dashboard',
  Refresh3DPeriod = '[Event] Refresh3DPeriod',
  ExportAsPNG = '[Event] Export As PNG',
  ExportAsPDF = '[Event] Export As PDF',
  Open3DMenu = '[Event] Open 3D Menu',
  PostAxes = '[Event] Post Axes',
  ChartChanged = '[Event] Chart changed',
  ResizeCanvasOnDragStop = '[Event] Resize Canvas on Drag Stop',
  ResizeCanvasOnResizeStop = '[Event] Resize Canvas on Resize Stop',
  TransformTo2DChart = '[Event] Transform 3D to 2D',
  TransformTo3DChart = '[Event] Transform 2D back to 3D',
  ResizeChart = '[Event] Resize Chart',
  SizeScaleChart = '[Event] Size Scale Chart',
  XScaleChart = '[Event] XScale Chart',
  ZScaleChart = '[Event] ZScale Chart',
  ResetChart = '[Event] Reset Chart',
  RotateChart = '[Event] Rotate Chart',
  SliceChart = '[Event] Slice Chart',
  RepositionChart = '[Event] Reposition Chart',
  ZoomChart = '[Event] Zoom Chart',
  Use2DChart = '[Event] Use 2d chart',
  Use3DChart = '[Event] Use 3d chart',
  UseLabelChart = '[Event] Use Label Chart',
  UseGridChart = '[Event] Use Grid Chart',
  EventContactAdded = '[Event] Event Contact Added',
  ScheduledMenuOpened = '[Event] Scheduled Menu Opened',
  ReadDashboards = '[Event] Read dashboards',
  GridsterWindowResizeStopped = '[Event] Gridster Window Resize Stopped',
  UnsubscribeFromInterval = '[Event] Unsubscribe from interval',
  UpdateDataPointActive = '[Event] Update data point active',
  UpdateDataPointIsComputed = '[Event] Update data point is computed',
  ParentUserSelected = '[Event] Parent User Selected',
  RefreshViewsGrid = '[Event] Refresh Views Grid',
  InitializeEvent = '[Event] Initialize Event',
  UncheckUsersMenu = '[Event] Uncheck User Menu',
  ConfigFetched = '[Event] Config Fetched',
  NewDataView = '[Event] New data view',
  NewDashboard = '[Event] New dashboard',
  PrimeOne = '[Event] Prime one event',
  DataViewPrimed = '[Event] Data view primed',
  PrimeAll = '[Event] Prime all events',
  OpenModal = '[Event] Open modal dialog',
  DisplayToast = '[Event] Display toast message',
  DeleteFolders = '[Event] Delete folders',
  DeleteFolderItems = '[Event] Delete items',
  DeleteThings = '[Event] Delete things',
  DeleteAlarms = '[Event] Delete alarms',
  DeleteAlarmHistories = '[Event] Delete alarm histories',
  DeleteAssets = '[Event] Delete assets',
  DeleteAlarmContacts = '[Event] Delete alarm contacts',
  DeleteEventContacts = '[Event] Delete event contacts',
  DeleteTriggerPoints = '[Event] Delete trigger points',
  DeleteTriggerPointProfiles = '[Event] Delete trigger point profiles',
  DeleteDashboards = '[Event] Delete dashboards',
  DeleteDataPoints = '[Event] Delete data points',
  DeleteDataViews = '[Event] Delete data views',
  DeleteReports = '[Event] Delete reports',
  DeleteCustomPeriods = '[Event] Delete custom periods',
  DeleteScheduledEvents = '[Event] Delete scheduled events',
  DeleteEvents = '[Event] Delete events',
  DeleteEventTypes = '[Event] Delete event types',
  DeleteAppliedThing = '[Event] delete applied thing',
  DeleteCompanies = '[Event] delete company',
  DeleteTickets = '[Event] delete tickets',
  DeleteUserRoles = '[Event] delete user role',
  DeleteLocations = '[Event] delete locations',
  DeleteAssetCustomers = '[Event] delete asset customers',
  DeleteAppliedDataPoint = '[Event] delete applied data point',
  DashboardNameBreadcrumb = '[Event] dashboard name breadcrumb',
  AllowAxisSelection = '[Event] allow axis selection',
  DisallowAxisSelection = '[Event] disallow axis selection',
  FilterValueChanged = '[Event] filter value changed',
  GridsterItemDragStopped = '[Event] Gridster item drag stopped',
  GridsterItemDragStarted = '[Event] Gridster item drag started',
  GridsterItemResizeStopped = '[Event] Gridster item resize stopped',
  GridsterItemResizeStarted = '[Event] Gridster item resize started',
  GridsterItemChanged = '[Event] gridster item changed',
  GridsterItemResized = '[Event] gridster item resized',
  GridsterGridSizeChanged = '[Event] grid size changed',
  GridsterItemInitialized = '[Event] gridster item initialized',
  GridsterInitialized = '[Event] gridster initialized',
  NotifyDrawer = '[Event] Notify right side drawer',
  OpenDeleteAppliedDataPointModal = '[Event] Open delete applied data point modal',
  OpenEventContactMenu = '[Event] Open event contact menu',
  CloseEventContactMenu = '[Event] Close event contact menu',
  OpenAlarmContactMenu = '[Event] Open alarm contact menu',
  OpenTicketUserMenu = '[Event] Open ticket user menu',
  OpenDashboardMenu = '[Event] Open dashboard menu',
  OpenDataViewMenu = '[Event] Open data view menu',
  OpenReportContactMenu = '[Event] Open report contact menu',
  OpenReportWizard = '[Event] Open report wizard',
  OpenDataPointMenu = '[Event] Open data point menu',
  OpenLocationDeleteModal = '[Event] Open Location Delete Modal',
  OpenItemDeleteModal = '[Event] Open item delete modal',
  OpenThingDeleteModal = '[Event] Open thing delete modal',
  OpenAssetCustomerDeleteModal = '[Event] Open asset customer delete modal',
  OpenDashboardDeleteModal = '[Event] Open dashboard delete modal',
  OpenDataViewDeleteModal = '[Event] Open data view delete modal',
  OpenDataPointDeleteModal = '[Event] Open data point delete modal',
  OpenDataUploadModal = '[Event] Open data upload modal',
  OpenCustomPeriodDeleteModal = '[Event] Open custom period delete modal',
  OpenAssetDeleteModal = '[Event] Open asset delete modal',
  OpenAppliedThingDeleteModal = '[Event] Open applied thing delete modal',
  OpenFolderDeleteModal = '[Event] Open folder delete modal',
  OpenEventDeleteModal = '[Event] Open event delete modal',
  OpenEventTypeDeleteModal = '[Event] Open event type delete modal',
  OpenCompanyDeleteModal = '[Event] Open company delete modal',
  OpenScheduledEventDeleteModal = '[Event] Open scheduled event delete modal',
  OpenTicketDeleteModal = '[Event] Open ticket delete modal',
  OpenAlarmHistoryDeleteModal = '[Event] Open alarm history delete modal',
  OpenAlarmDeleteModal = '[Event] Open alarm delete modal',
  OpenAlarmContactDeleteModal = '[Event] Open alarm contact delete modal',
  OpenEventContactDeleteModal = '[Event] Open event contact delete modal',
  OpenUserDeleteModal = '[Event] Open user delete modal',
  OpenUserRoleDeleteModal = '[Event] Open user role delete modal',
  OpenCopyDashboardModal = '[Event] Open copy dashboard modal',
  OpenCopyDataViewModal = '[Event] Open copy data view modal',
  OpenCopyDataPointModal = '[Event] Open copy data point modal',
  OpenReportDeleteModal = '[Event] Open report delete modal',
  CopyDashboards = '[Event] Copy dashboards',
  CopyDataPoints = '[Event] Copy data points',
  CopyDataViews = '[Event] Copy data views',
  CloseAlarmContactMenu = '[Event] Close alarm contact menu',
  CloseDataPointMenu = '[Event] Close data point menu',
  CloseUserPreferencesModal = '[Event] Close user preferences modal',
  OpenUserPreferencesModal = '[Event] Open user preferences modal',
  OpenTriggerPointDeleteModal = '[Event] Open trigger point delete modal',
  OpenTriggerPointProfileDeleteModal = '[Events] Open trigger point profile delete modal',
  ReadEventContactsMenuData = '[Events] Read event contacts menu data',
  RedrawChart = '[Events] Redraw chart',
  RedrawSingleChart = '[Events] Redraw single chart',
  RefreshComponent = '[Event] Refresh component',
  RefreshTriggerPoints = '[Event] Refresh trigger points',
  RefreshAlarmContacts = '[Event] Refresh alarm contacts',
  RefreshTriggerPointProfiles = '[Event] Refresh trigger point profiles',
  RefreshAssets = '[Event] Refresh assets',
  RefreshAlarms = '[Event] Refresh alarms',
  RefreshSideMenu = '[Event] Refresh side menu',
  ToggleNavigation = '[Event] Toggle navigation',
  OpenDataCommanderLiteModal = '[Event] Open Data Commander Lite modal',
  OpenContactReportDeleteModal = '[Event] Open Contact Report Delete modal',
  DeleteReportContacts = '[Event] Delete Report Contacts',
  DeleteUsers = '[Event] Delete Users',
  UseDualChart = '[Event] Use Dual Chart',
  UseScatterChart = '[Event] Use Scatter Chart',
  UpdateFullName = '[Event] Update Full Name'
}

export class RedirectToDefault implements Action {
  readonly type = EventsActionTypes.RedirectToDefault;
  constructor(public payload: any) {}
}

export class UpdateGridChart implements Action {
  readonly type = EventsActionTypes.UpdateGridChart;
  constructor(public payload: any) {}
}

export class StartGridSpinner implements Action {
  readonly type = EventsActionTypes.StartGridSpinner;
  constructor(public payload: any) {}
}

export class UserChangedFromSuper implements Action {
  readonly type = EventsActionTypes.UserChangedFromSuper;
  constructor(public payload: any) {}
}

export class StopGridSpinner implements Action {
  readonly type = EventsActionTypes.StopGridSpinner;
  constructor(public payload: any) {}
}

export class OpenDeleteChartItemModal implements Action {
  readonly type = EventsActionTypes.OpenDeleteChartItemModal;
  constructor(public payload: any) {}
}

export class DeleteChartItem implements Action {
  readonly type = EventsActionTypes.DeleteChartItem;
  constructor(public payload: any) {}
}

export class UpdateChartItems implements Action {
  readonly type = EventsActionTypes.UpdateChartItems;
  constructor(public payload: any) {}
}

export class RefreshGridChartFinished implements Action {
  readonly type = EventsActionTypes.RefreshGridChartFinished;
  constructor(public payload: any) {}
}

export class RefreshGridChart implements Action {
  readonly type = EventsActionTypes.RefreshGridChart;
  constructor(public payload: any) {}
}

export class RefreshGridChartHeader implements Action {
  readonly type = EventsActionTypes.RefreshGridChartHeader;
  constructor(public payload: any) {}
}

export class UseLabelChart implements Action {
  readonly type = EventsActionTypes.UseLabelChart;
  constructor(public payload: any) {}
}

export class UseGridChart implements Action {
  readonly type = EventsActionTypes.UseGridChart;
  constructor(public payload: any) {}
}

export class RefreshFormula implements Action {
  readonly type = EventsActionTypes.RefreshFormula;
  constructor(public payload: any) {}
}

export class PopulateFormula implements Action {
  readonly type = EventsActionTypes.PopulateFormula;
  constructor(public payload: any) {}
}

export class PostFormula implements Action {
  readonly type = EventsActionTypes.PostFormula;
  constructor(public payload: any) {}
}

export class RereadColumnNames implements Action {
  readonly type = EventsActionTypes.RereadColumnNames;
  constructor(public payload: any) {}
}

export class ReadDataPointConfigs implements Action {
  readonly type = EventsActionTypes.ReadDataPointConfigs;
  constructor(public payload: any) {}
}

export class ReloadPage implements Action {
  readonly type = EventsActionTypes.ReloadPage;
  constructor(public payload: any) {}
}

export class InitComputedActive implements Action {
  readonly type = EventsActionTypes.InitComputedActive;
  constructor(public payload: any) {}
}

export class HideHeaderLabels implements Action {
  readonly type = EventsActionTypes.HideHeaderLabels;
  constructor(public payload: any) {}
}

export class PostAxisValues implements Action {
  readonly type = EventsActionTypes.PostAxisValues;
  constructor(public payload: any) {}
}

export class HideAlignOnThreeD implements Action {
  readonly type = EventsActionTypes.HideAlignOnThreeD;
  constructor(public payload: any) {}
}

export class AddLetterToAdvancedExpression implements Action {
  readonly type = EventsActionTypes.AddLetterToAdvancedExpression;
  constructor(public payload: any) {}
}

export class DoNotUseAdvancedExpression implements Action {
  readonly type = EventsActionTypes.DoNotUseAdvancedExpression;
  constructor(public payload: any) {}
}

export class UseAdvancedExpression implements Action {
  readonly type = EventsActionTypes.UseAdvancedExpression;
  constructor(public payload: any) {}
}

export class NotifyDashboard implements Action {
  readonly type = EventsActionTypes.NotifyDashboard;
  constructor(public payload: any) {}
}

export class UseScatterChart implements Action {
  readonly type = EventsActionTypes.UseScatterChart;
  constructor(public payload: any) {}
}

export class UpdateDashboardMenuItem implements Action {
  readonly type = EventsActionTypes.UpdateDashboardMenuItem;
  constructor(public payload: any) {}
}

export class UseDualChart implements Action {
  readonly type = EventsActionTypes.UseDualChart;
  constructor(public payload: any) {}
}

export class Refresh3DPeriod implements Action {
  readonly type = EventsActionTypes.Refresh3DPeriod;
  constructor(public payload: any) {}
}

export class Open3DMenu implements Action {
  readonly type = EventsActionTypes.Open3DMenu;
  constructor(public payload: any) {}
}

export class PostAxes implements Action {
  readonly type = EventsActionTypes.PostAxes;
  constructor(public payload: any) {}
}

export class ChartChanged implements Action {
  readonly type = EventsActionTypes.ChartChanged;
  constructor(public payload: any) {}
}

export class ResizeChart implements Action {
  readonly type = EventsActionTypes.ResizeChart;
  constructor(public payload: any) {}
}

export class SizeScaleChart implements Action {
  readonly type = EventsActionTypes.SizeScaleChart;
  constructor(public payload: any) {}
}

export class XScaleChart implements Action {
  readonly type = EventsActionTypes.XScaleChart;
  constructor(public payload: any) {}
}

export class ZScaleChart implements Action {
  readonly type = EventsActionTypes.ZScaleChart;
  constructor(public payload: any) {}
}

export class ZoomChart implements Action {
  readonly type = EventsActionTypes.ZoomChart;
  constructor(public payload: any) {}
}

export class SliceChart implements Action {
  readonly type = EventsActionTypes.SliceChart;
  constructor(public payload: any) {}
}

export class ResetChart implements Action {
  readonly type = EventsActionTypes.ResetChart;
  constructor(public payload: any) {}
}

export class RepositionChart implements Action {
  readonly type = EventsActionTypes.RepositionChart;
  constructor(public payload: any) {}
}

export class RotateChart implements Action {
  readonly type = EventsActionTypes.RotateChart;
  constructor(public payload: any) {}
}

export class TransformTo2DChart implements Action {
  readonly type = EventsActionTypes.TransformTo2DChart;
  constructor(public payload: any) {}
}

export class TransformTo3DChart implements Action {
  readonly type = EventsActionTypes.TransformTo3DChart;
  constructor(public payload: any) {}
}

export class ResizeCanvasOnDragStop implements Action {
  readonly type = EventsActionTypes.ResizeCanvasOnDragStop;
  constructor(public payload: any) {}
}

export class ResizeCanvasOnResizeStop implements Action {
  readonly type = EventsActionTypes.ResizeCanvasOnResizeStop;
  constructor(public payload: any) {}
}

export class Use2DChart implements Action {
  readonly type = EventsActionTypes.Use2DChart;
  constructor(public payload: any) {}
}

export class Use3DChart implements Action {
  readonly type = EventsActionTypes.Use3DChart;
  constructor(public payload: any) {}
}

export class EventContactAdded implements Action {
  readonly type = EventsActionTypes.EventContactAdded;
  constructor(public payload: any) {}
}

export class ScheduledMenuOpened implements Action {
  readonly type = EventsActionTypes.ScheduledMenuOpened;
  constructor(public payload: any) {}
}

export class InitializeEvent implements Action {
  readonly type = EventsActionTypes.InitializeEvent;
  constructor(public payload: any) {}
}

export class ReadDashboards implements Action {
  readonly type = EventsActionTypes.ReadDashboards;
  constructor(public payload: any) {}
}

export class AllowAxisSelection implements Action {
  readonly type = EventsActionTypes.AllowAxisSelection;
  constructor(public payload: any) {}
}

export class DisallowAxisSelection implements Action {
  readonly type = EventsActionTypes.DisallowAxisSelection;
  constructor(public payload: any) {}
}

export class UpdateDataPointActive implements Action {
  readonly type = EventsActionTypes.UpdateDataPointActive;
  constructor(public payload: any) {}
}

export class UnsubscribeFromInterval implements Action {
  readonly type = EventsActionTypes.UnsubscribeFromInterval;
  constructor(public payload: any) {}
}

export class UpdateDataPointIsComputed implements Action {
  readonly type = EventsActionTypes.UpdateDataPointIsComputed;
  constructor(public payload: any) {}
}

export class CloseDataPointMenu implements Action {
  readonly type = EventsActionTypes.CloseDataPointMenu;
  constructor(public payload: any) {}
}

export class ParentUserSelected implements Action {
  readonly type = EventsActionTypes.ParentUserSelected;
  constructor(public payload: any) {}
}

export class DeleteLocations implements Action {
  readonly type = EventsActionTypes.DeleteLocations;
  constructor(public payload: any) {}
}

export class OpenLocationDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenLocationDeleteModal;
  constructor(public payload: any) {}
}

export class FilterValueChanged implements Action {
  readonly type = EventsActionTypes.FilterValueChanged;
  constructor(public payload: any) {}
}

export class RefreshViewsGrid implements Action {
  readonly type = EventsActionTypes.RefreshViewsGrid;
  constructor(public payload: any) {}
}

export class UncheckUsersMenu implements Action {
  readonly type = EventsActionTypes.UncheckUsersMenu;
  constructor(public payload: any) {}
}

export class ConfigFetched implements Action {
  readonly type = EventsActionTypes.ConfigFetched;
  constructor(public payload: any) {}
}

export class UpdateFullName implements Action {
  readonly type = EventsActionTypes.UpdateFullName;
  constructor(public payload: any) {}
}

export class OpenEventContactMenu implements Action {
  readonly type = EventsActionTypes.OpenEventContactMenu;
  constructor(public payload: any) {}
}

export class CloseEventContactMenu implements Action {
  readonly type = EventsActionTypes.CloseEventContactMenu;
  constructor(public payload: any) {}
}

export class ReadEventContactsMenuData implements Action {
  readonly type = EventsActionTypes.ReadEventContactsMenuData;
  constructor(public payload: any) {}
}

export class GridsterItemResizeStopped implements Action {
  readonly type = EventsActionTypes.GridsterItemResizeStopped;
  constructor(public payload: any) {}
}

export class GridsterWindowResizeStopped implements Action {
  readonly type = EventsActionTypes.GridsterWindowResizeStopped;
  constructor(public payload: any) {}
}

export class GridsterItemResizeStarted implements Action {
  readonly type = EventsActionTypes.GridsterItemResizeStarted;
  constructor(public payload: any) {}
}

export class GridsterItemDragStopped implements Action {
  readonly type = EventsActionTypes.GridsterItemDragStopped;
  constructor(public payload: any) {}
}

export class ExportAsPNG implements Action {
  readonly type = EventsActionTypes.ExportAsPNG;
  constructor(public payload: any) {}
}

export class ExportAsPDF implements Action {
  readonly type = EventsActionTypes.ExportAsPDF;
  constructor(public payload: any) {}
}

export class GridsterItemDragStarted implements Action {
  readonly type = EventsActionTypes.GridsterItemDragStarted;
  constructor(public payload: any) {}
}

export class GridsterGridSizeChanged implements Action {
  readonly type = EventsActionTypes.GridsterGridSizeChanged;
  constructor(public payload: any) {}
}

export class GridsterItemResized implements Action {
  readonly type = EventsActionTypes.GridsterItemResized;
  constructor(public payload: any) {}
}

export class GridsterItemChanged implements Action {
  readonly type = EventsActionTypes.GridsterItemChanged;
  constructor(public payload: any) {}
}

export class GridsterInitialized implements Action {
  readonly type = EventsActionTypes.GridsterInitialized;
  constructor(public payload: any) {}
}

export class GridsterItemInitialized implements Action {
  readonly type = EventsActionTypes.GridsterItemInitialized;
  constructor(public payload: any) {}
}

export class RedrawChart implements Action {
  readonly type = EventsActionTypes.RedrawChart;
  constructor(public payload: any) {}
}

export class RedrawSingleChart implements Action {
  readonly type = EventsActionTypes.RedrawSingleChart;
  constructor(public payload: any) {}
}

export class OpenDeleteAppliedDataPointModal implements Action {
  readonly type = EventsActionTypes.OpenDeleteAppliedDataPointModal;
  constructor(public payload: any) {}
}

export class DeleteAppliedDataPoint implements Action {
  readonly type = EventsActionTypes.DeleteAppliedDataPoint;
  constructor(public payload: any) {}
}

export class DataViewPrimed implements Action {
  readonly type = EventsActionTypes.DataViewPrimed;
  constructor(public payload: any) {}
}

export class OpenDataUploadModal implements Action {
  readonly type = EventsActionTypes.OpenDataUploadModal;
  constructor(public payload: any) {}
}

export class OpenUserDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenUserDeleteModal;
  constructor(public payload: any) {}
}

export class OpenReportDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenReportDeleteModal;
  constructor(public payload: any) {}
}

export class DeleteUsers implements Action {
  readonly type = EventsActionTypes.DeleteUsers;
  constructor(public payload: any) {}
}

export class DeleteReports implements Action {
  readonly type = EventsActionTypes.DeleteReports;
  constructor(public payload: any) {}
}

export class DeleteReportContacts implements Action {
  readonly type = EventsActionTypes.DeleteReportContacts;
  constructor(public payload: any) {}
}

export class OpenContactReportDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenContactReportDeleteModal;
  constructor(public payload: any) {}
}

export class OpenDataCommanderLiteModal implements Action {
  readonly type = EventsActionTypes.OpenDataCommanderLiteModal;
  constructor(public payload: any) {}
}

export class DashboardNameBreadcrumb implements Action {
  readonly type = EventsActionTypes.DashboardNameBreadcrumb;
  constructor(public payload: any) {}
}

export class RefreshComponent implements Action {
  readonly type = EventsActionTypes.RefreshComponent;
  constructor(public payload: any) {}
}

export class OpenFolderDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenFolderDeleteModal;
  constructor(public payload: any) {}
}

export class OpenCopyDashboardModal implements Action {
  readonly type = EventsActionTypes.OpenCopyDashboardModal;
  constructor(public payload: any) {}
}

export class CopyDashboards implements Action {
  readonly type = EventsActionTypes.CopyDashboards;
  constructor(public payload: any) {}
}

export class OpenCopyDataViewModal implements Action {
  readonly type = EventsActionTypes.OpenCopyDataViewModal;
  constructor(public payload: any) {}
}

export class CopyDataViews implements Action {
  readonly type = EventsActionTypes.CopyDataViews;
  constructor(public payload: any) {}
}

export class OpenCopyDataPointModal implements Action {
  readonly type = EventsActionTypes.OpenCopyDataPointModal;
  constructor(public payload: any) {}
}

export class CopyDataPoints implements Action {
  readonly type = EventsActionTypes.CopyDataPoints;
  constructor(public payload: any) {}
}

export class ToggleNavigation implements Action {
  readonly type = EventsActionTypes.ToggleNavigation;
  constructor(public payload: any) {}
}

export class OpenAlarmHistoryDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenAlarmHistoryDeleteModal;
  constructor(public payload: any) {}
}

export class DeleteAlarmHistories implements Action {
  readonly type = EventsActionTypes.DeleteAlarmHistories;
  constructor(public payload: any) {}
}

export class OpenAssetCustomerDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenAssetCustomerDeleteModal;
  constructor(public payload: any) {}
}

export class DeleteTickets implements Action {
  readonly type = EventsActionTypes.DeleteTickets;
  constructor(public payload: any) {}
}

export class OpenTicketDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenTicketDeleteModal;
  constructor(public payload: any) {}
}

export class DeleteAssetCustomers implements Action {
  readonly type = EventsActionTypes.DeleteAssetCustomers;
  constructor(public payload: any) {}
}

export class DeleteScheduledEvents implements Action {
  readonly type = EventsActionTypes.DeleteScheduledEvents;
  constructor(public payload: any) {}
}

export class DeleteCompanies implements Action {
  readonly type = EventsActionTypes.DeleteCompanies;
  constructor(public payload: any) {}
}

export class DeleteUserRoles implements Action {
  readonly type = EventsActionTypes.DeleteUserRoles;
  constructor(public payload: any) {}
}

export class OpenScheduledEventDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenScheduledEventDeleteModal;
  constructor(public payload: any) {}
}

export class OpenCompanyDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenCompanyDeleteModal;
  constructor(public payload: any) {}
}

export class OpenUserRoleDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenUserRoleDeleteModal;
  constructor(public payload: any) {}
}

export class DeleteEventTypes implements Action {
  readonly type = EventsActionTypes.DeleteEventTypes;
  constructor(public payload: any) {}
}

export class DeleteEvents implements Action {
  readonly type = EventsActionTypes.DeleteEvents;
  constructor(public payload: any) {}
}

export class OpenEventTypeDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenEventTypeDeleteModal;
  constructor(public payload: any) {}
}

export class OpenEventDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenEventDeleteModal;
  constructor(public payload: any) {}
}

export class OpenThingDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenThingDeleteModal;
  constructor(public payload: any) {}
}

export class DeleteThings implements Action {
  readonly type = EventsActionTypes.DeleteThings;
  constructor(public payload: any) {}
}

export class DeleteAppliedThing implements Action {
  readonly type = EventsActionTypes.DeleteAppliedThing;
  constructor(public payload: any) {}
}

export class OpenAppliedThingDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenAppliedThingDeleteModal;
  constructor(public payload: any) {}
}

export class OpenReportContactMenu implements Action {
  readonly type = EventsActionTypes.OpenReportContactMenu;
  constructor(public payload: any) {}
}

export class RefreshSideMenu implements Action {
  readonly type = EventsActionTypes.RefreshSideMenu;
  constructor(public payload: any) {}
}

export class DeleteDataPoints implements Action {
  readonly type = EventsActionTypes.DeleteDataPoints;
  constructor(public payload: any) {}
}

export class DeleteDataViews implements Action {
  readonly type = EventsActionTypes.DeleteDataViews;
  constructor(public payload: any) {}
}

export class DeleteDashboards implements Action {
  readonly type = EventsActionTypes.DeleteDashboards;
  constructor(public payload: any) {}
}

export class DeleteCustomPeriods implements Action {
  readonly type = EventsActionTypes.DeleteCustomPeriods;
  constructor(public payload: any) {}
}

export class DeleteAlarmContacts implements Action {
  readonly type = EventsActionTypes.DeleteAlarmContacts;
  constructor(public payload: any) {}
}

export class DeleteEventContacts implements Action {
  readonly type = EventsActionTypes.DeleteEventContacts;
  constructor(public payload: any) {}
}

export class OpenAlarmContactDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenAlarmContactDeleteModal;
  constructor(public payload: any) {}
}

export class OpenEventContactDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenEventContactDeleteModal;
  constructor(public payload: any) {}
}

export class OpenDashboardMenu implements Action {
  readonly type = EventsActionTypes.OpenDashboardMenu;
  constructor(public payload: any) {}
}

export class OpenDataViewMenu implements Action {
  readonly type = EventsActionTypes.OpenDataViewMenu;
  constructor(public payload: any) {}
}

export class OpenDataPointMenu implements Action {
  readonly type = EventsActionTypes.OpenDataPointMenu;
  constructor(public payload: any) {}
}

export class OpenDashboardDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenDashboardDeleteModal;
  constructor(public payload: any) {}
}

export class OpenCustomPeriodDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenCustomPeriodDeleteModal;
  constructor(public payload: any) {}
}

export class OpenDataViewDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenDataViewDeleteModal;
  constructor(public payload: any) {}
}

export class OpenDataPointDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenDataPointDeleteModal;
  constructor(public payload: any) {}
}

export class NotifyDrawer implements Action {
  readonly type = EventsActionTypes.NotifyDrawer;
  constructor(public payload: any) {}
}

export class PrimeOne implements Action {
  readonly type = EventsActionTypes.PrimeOne;
  constructor(public payload: any) {}
}

export class PrimeAll implements Action {
  readonly type = EventsActionTypes.PrimeAll;
  constructor(public payload: any) {}
}

export class OpenModal implements Action {
  readonly type = EventsActionTypes.OpenModal;
  constructor(public payload: any) {}
}

export class DeleteFolders implements Action {
  readonly type = EventsActionTypes.DeleteFolders;
  constructor(public payload: any) {}
}

export class DeleteFolderItems implements Action {
  readonly type = EventsActionTypes.DeleteFolderItems;
  constructor(public payload: any) {}
}

export class DeleteAssets implements Action {
  readonly type = EventsActionTypes.DeleteAssets;
  constructor(public payload: any) {}
}

export class DeleteAlarms implements Action {
  readonly type = EventsActionTypes.DeleteAlarms;
  constructor(public payload: any) {}
}

export class DeleteTriggerPoints implements Action {
  readonly type = EventsActionTypes.DeleteTriggerPoints;
  constructor(public payload: any) {}
}

export class DeleteTriggerPointProfiles implements Action {
  readonly type = EventsActionTypes.DeleteTriggerPointProfiles;
  constructor(public payload: any) {}
}

export class DisplayToast implements Action {
  readonly type = EventsActionTypes.DisplayToast;
  constructor(public payload: any) {}
}

export class NewDataView implements Action {
  readonly type = EventsActionTypes.NewDataView;
  constructor(public payload: any) {}
}

export class NewDashboard implements Action {
  readonly type = EventsActionTypes.NewDashboard;
  constructor(public payload: any) {}
}

export class OpenAlarmContactMenu implements Action {
  readonly type = EventsActionTypes.OpenAlarmContactMenu;
  constructor(public payload: any) {}
}

export class CloseAlarmContactMenu implements Action {
  readonly type = EventsActionTypes.CloseAlarmContactMenu;
  constructor(public payload: any) {}
}

export class OpenAlarmDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenAlarmDeleteModal;
  constructor(public payload: any) {}
}

export class OpenTicketUserMenu implements Action {
  readonly type = EventsActionTypes.OpenTicketUserMenu;
  constructor(public payload: any) {}
}

export class OpenItemDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenItemDeleteModal;
  constructor(public payload: any) {}
}

export class OpenAssetDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenAssetDeleteModal;
  constructor(public payload: any) {}
}

export class OpenTriggerPointDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenTriggerPointDeleteModal;
  constructor(public payload: any) {}
}

export class OpenTriggerPointProfileDeleteModal implements Action {
  readonly type = EventsActionTypes.OpenTriggerPointProfileDeleteModal;
  constructor(public payload: any) {}
}

export class CloseUserPreferencesModal implements Action {
  readonly type = EventsActionTypes.CloseUserPreferencesModal;
  constructor(public payload: any) {}
}

export class OpenUserPreferencesModal implements Action {
  readonly type = EventsActionTypes.OpenUserPreferencesModal;
  constructor(public payload: any) {}
}

export class RefreshAssets implements Action {
  readonly type = EventsActionTypes.RefreshAssets;
  constructor(public payload: any) {}
}

export class RefreshAlarms implements Action {
  readonly type = EventsActionTypes.RefreshAlarms;
  constructor(public payload: any) {}
}

export class RefreshTriggerPoints implements Action {
  readonly type = EventsActionTypes.RefreshTriggerPoints;
  constructor(public payload: any) {}
}

export class RefreshTriggerPointProfiles implements Action {
  readonly type = EventsActionTypes.RefreshTriggerPointProfiles;
  constructor(public payload: any) {}
}

export class RefreshAlarmContacts implements Action {
  readonly type = EventsActionTypes.RefreshAlarmContacts;
  constructor(public payload: any) {}
}

export class OpenReportWizard implements Action {
  readonly type = EventsActionTypes.OpenReportWizard;
  constructor(public payload: any) {}
}

export class OpenDeleteDashboardModal implements Action {
  readonly type = EventsActionTypes.OpenDeleteDashboardModal;
  constructor(public payload: any) {}
}

export class DeleteDashboard implements Action {
  readonly type = EventsActionTypes.DeleteDashboard;
  constructor(public payload: any) {}
}

export type EventsActions =
  | PrimeAll
  | PrimeOne
  | OpenModal
  | DisplayToast
  | NewDashboard
  | NewDataView
  | DeleteFolders
  | RefreshAssets
  | RefreshTriggerPoints
  | OpenTriggerPointDeleteModal
  | DeleteTriggerPoints
  | CloseUserPreferencesModal
  | OpenUserPreferencesModal
  | OpenTriggerPointProfileDeleteModal
  | DeleteTriggerPointProfiles
  | RefreshTriggerPointProfiles
  | DeleteFolderItems
  | OpenTicketUserMenu
  | DeleteAssets
  | OpenItemDeleteModal
  | OpenAssetDeleteModal
  | OpenAlarmContactMenu
  | OpenAlarmDeleteModal
  | DeleteAlarms
  | RefreshAlarms
  | CloseAlarmContactMenu
  | NotifyDrawer
  | OpenAlarmContactDeleteModal
  | DeleteAlarmContacts
  | RefreshAlarmContacts
  | RefreshSideMenu
  | OpenEventContactDeleteModal
  | DeleteEventContacts
  | DeleteDataPoints
  | DeleteDashboards
  | DeleteDataViews
  | OpenDataPointDeleteModal
  | OpenDataViewDeleteModal
  | OpenDashboardDeleteModal
  | DeleteCustomPeriods
  | OpenCustomPeriodDeleteModal
  | OpenDashboardMenu
  | OpenDataPointMenu
  | OpenDataViewMenu
  | OpenAppliedThingDeleteModal
  | DeleteAppliedThing
  | DeleteThings
  | OpenThingDeleteModal
  | OpenEventTypeDeleteModal
  | DeleteEventTypes
  | OpenCompanyDeleteModal
  | DeleteCompanies
  | OpenUserRoleDeleteModal
  | DeleteUserRoles
  | DeleteScheduledEvents
  | OpenScheduledEventDeleteModal
  | OpenReportContactMenu
  | DeleteAssetCustomers
  | OpenAssetCustomerDeleteModal
  | DeleteTickets
  | OpenTicketDeleteModal
  | OpenAlarmHistoryDeleteModal
  | DeleteAlarmHistories
  | ToggleNavigation
  | OpenCopyDashboardModal
  | OpenCopyDataViewModal
  | OpenCopyDataPointModal
  | CopyDataPoints
  | CopyDataViews
  | CopyDashboards
  | RefreshComponent
  | OpenFolderDeleteModal
  | OpenReportWizard
  | DashboardNameBreadcrumb
  | OpenDataCommanderLiteModal
  | OpenContactReportDeleteModal
  | DeleteReports
  | OpenReportDeleteModal
  | DeleteReports
  | DeleteReportContacts
  | OpenUserDeleteModal
  | DataViewPrimed
  | DeleteUsers
  | DeleteEvents
  | OpenEventDeleteModal
  | OpenDataUploadModal
  | OpenDeleteAppliedDataPointModal
  | DeleteAppliedDataPoint
  | RedrawChart
  | RedrawSingleChart
  | GridsterInitialized
  | GridsterItemChanged
  | GridsterItemResized
  | GridsterGridSizeChanged
  | GridsterItemInitialized
  | GridsterItemResizeStopped
  | GridsterItemResizeStarted
  | GridsterItemDragStopped
  | GridsterItemDragStarted
  | UpdateFullName
  | ReadEventContactsMenuData
  | OpenEventContactMenu
  | CloseEventContactMenu
  | ConfigFetched
  | UncheckUsersMenu
  | RefreshViewsGrid
  | FilterValueChanged
  | OpenLocationDeleteModal
  | DeleteLocations
  | ParentUserSelected
  | CloseDataPointMenu
  | UpdateDataPointIsComputed
  | UpdateDataPointActive
  | GridsterWindowResizeStopped
  | AllowAxisSelection
  | DeleteChartItem
  | OpenDeleteChartItemModal
  | DisallowAxisSelection
  | ReadDashboards
  | InitializeEvent
  | ScheduledMenuOpened
  | EventContactAdded
  | Use3DChart
  | Use2DChart
  | UseScatterChart
  | TransformTo2DChart
  | TransformTo3DChart
  | ResetChart
  | SliceChart
  | ZoomChart
  | RotateChart
  | XScaleChart
  | ZScaleChart
  | SizeScaleChart
  | ResizeChart
  | RepositionChart
  | ChartChanged
  | Open3DMenu
  | ExportAsPNG
  | ExportAsPDF
  | NotifyDashboard
  | PostAxes
  | UseDualChart
  | Refresh3DPeriod
  | ResizeCanvasOnResizeStop
  | ResizeCanvasOnDragStop
  | UseAdvancedExpression
  | DoNotUseAdvancedExpression
  | AddLetterToAdvancedExpression
  | HideAlignOnThreeD
  | PostAxisValues
  | HideHeaderLabels
  | InitComputedActive
  | ReloadPage
  | RereadColumnNames
  | ReadDataPointConfigs
  | PostFormula
  | UseLabelChart
  | UseGridChart
  | PopulateFormula
  | RefreshFormula
  | RefreshGridChart
  | RefreshGridChartFinished
  | RefreshGridChartHeader
  | UpdateChartItems
  | UpdateGridChart
  | StartGridSpinner
  | StopGridSpinner
  | DeleteDashboard
  | OpenDeleteDashboardModal
  | UpdateDashboardMenuItem
  | UserChangedFromSuper
  | RedirectToDefault
  | UnsubscribeFromInterval;
