import { createEntityAdapter } from '@ngrx/entity';
import { Event } from '../interfaces/events.interface';
import { EventsActionTypes, EventsActions } from '../actions/events.actions';
import { EventsState, initialState } from '../states/events.state';

export function eventsReducer(
  state = initialState,
  action: EventsActions
): EventsState {
  switch (action.type) {
    case EventsActionTypes.PrimeOne: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DataViewPrimed: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.PrimeAll: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DisplayToast: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.NewDataView: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.NewDashboard: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteFolders: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteFolderItems: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteAssets: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteAlarms: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteTriggerPoints: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteTriggerPointProfiles: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteDataPoints: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteDataViews: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteDashboards: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshTriggerPointProfiles: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenTicketUserMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenItemDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenAssetDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenTriggerPointDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshAssets: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshAlarms: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshTriggerPoints: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.CloseUserPreferencesModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenUserPreferencesModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenTriggerPointProfileDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenAlarmContactMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenAlarmDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.CloseAlarmContactMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.NotifyDrawer: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteAlarmContacts: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteEventContacts: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenAlarmContactDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenEventContactDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDataPointDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDataViewDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDashboardDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDashboardDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenCustomPeriodDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteCustomPeriods: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshAlarmContacts: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshSideMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDashboardMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDataPointMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDataViewMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenAppliedThingDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteAppliedThing: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenThingDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteThings: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteEventTypes: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenEventTypeDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenCompanyDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenScheduledEventDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenUserRoleDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteCompanies: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteUserRoles: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteScheduledEvents: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenReportContactMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenAssetCustomerDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteAssetCustomers: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenTicketDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenFolderDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteTickets: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteAlarmHistories: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenAlarmHistoryDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ToggleNavigation: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenCopyDashboardModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenCopyDataPointModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenCopyDataViewModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.CopyDataViews: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.CopyDataPoints: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.CopyDashboards: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshComponent: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenReportWizard: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DashboardNameBreadcrumb: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDataCommanderLiteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenContactReportDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteReportContacts: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenReportDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteReports: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteUsers: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteUserRoles: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenUserRoleDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenUserDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenEventDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteEvents: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDataUploadModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDeleteAppliedDataPointModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteAppliedDataPoint: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RedrawChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RedrawSingleChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.GridsterInitialized: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.GridsterItemInitialized: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.GridsterItemChanged: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.GridsterItemResized: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.GridsterGridSizeChanged: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.GridsterItemDragStopped: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.GridsterItemDragStarted: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.GridsterItemResizeStopped: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.GridsterItemResizeStarted: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ReadEventContactsMenuData: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenEventContactMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.CloseEventContactMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UpdateFullName: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ConfigFetched: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UncheckUsersMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshViewsGrid: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.FilterValueChanged: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenLocationDeleteModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteLocations: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ParentUserSelected: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.CloseDataPointMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UpdateDataPointActive: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UpdateDataPointIsComputed: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.GridsterWindowResizeStopped: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UnsubscribeFromInterval: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.AllowAxisSelection: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DisallowAxisSelection: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ReadDashboards: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.InitializeEvent: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ScheduledMenuOpened: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.EventContactAdded: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.Use3DChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.Use2DChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ResizeCanvasOnResizeStop: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ResizeCanvasOnDragStop: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.SliceChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ZoomChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RotateChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RepositionChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.TransformTo2DChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.TransformTo3DChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ResetChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.XScaleChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ZScaleChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.SizeScaleChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ResizeChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ChartChanged: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.PostAxes: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.Open3DMenu: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ExportAsPDF: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ExportAsPNG: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.Refresh3DPeriod: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UseDualChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.NotifyDashboard: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UseAdvancedExpression: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DoNotUseAdvancedExpression: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.AddLetterToAdvancedExpression: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.HideAlignOnThreeD: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UseScatterChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.PostAxisValues: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.HideHeaderLabels: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.InitComputedActive: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ReloadPage: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.ReadDataPointConfigs: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RereadColumnNames: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.PostFormula: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.PopulateFormula: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshFormula: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UseLabelChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UseGridChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshGridChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshGridChartHeader: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RefreshGridChartFinished: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UpdateChartItems: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteChartItem: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDeleteChartItemModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.StopGridSpinner: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.StartGridSpinner: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UpdateGridChart: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.DeleteDashboard: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.OpenDeleteDashboardModal: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UserChangedFromSuper: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.UpdateDashboardMenuItem: {
      return { ...state, items: action.payload };
    }
    case EventsActionTypes.RedirectToDefault: {
      return { ...state, items: action.payload };
    }
    default:
      return state;
  }
}

const eventAdapter = createEntityAdapter<Event>();
